import React from "react";
import Layout from "../components/layout";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import { string, object } from "yup";
import Recaptcha from "react-recaptcha";
import axios from "axios";

const schema = object({
  message: object({
    name: string().required(),
    email: string()
      .required()
      .email(),
    body: string().required(),
  }),
  reCaptchaResponseToVerify: string().required(),
});

let reCaptchaRef;

export default class ContactPage extends React.Component {
  state = {
    flash: {},
  };

  render() {
    return (
      <Layout title="Contact">
        <Container className="contact">
          <Row>
            <Col>
              <h1 className="display-4">Contact</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert
                show={this.state.flash && this.state.flash.show}
                variant={this.state.flash && this.state.flash.variant}
              >
                {this.state.flash && this.state.flash.message}
              </Alert>
              <Card body className="p-4">
                <Row>
                  <Col md={6}>
                    <address>
                      <strong>Diamonite Plant</strong>
                      <br />
                      453 West McConkey Street
                      <br />
                      Shreve, Ohio 44676
                      <br />
                      Tel: (330) 567-2145
                      <br />
                      Fax: (330) 915-4925
                      <br />
                      <small>ISO 9001:2008 Certified</small>
                    </address>
                    <address>
                      <strong>Cesco Plant</strong>
                      <br />
                      416 Maple Avenue
                      <br />
                      Crooksville, Ohio 43731
                      <br />
                      Tel: (740) 982-2050
                      <br />
                      Fax: (740) 982-9396
                      <br />
                    </address>
                  </Col>
                  <Col md={6}>
                    <Formik
                      validationSchema={schema}
                      initialValues={{
                        message: { name: "", email: "", body: "" },
                      }}
                      onSubmit={async (values, actions) => {
                        try {
                          await axios.post(
                            `${process.env.AJAX_PREFIX || ""}/api/contact`,
                            values
                          );
                          this.setState({
                            flash: {
                              show: true,
                              variant: "success",
                              message: "Your message was successfully sent.",
                            },
                          });
                          actions.resetForm();
                        } catch (e) {
                          let message =
                            "We were unable to process the request.";
                          message +=
                            e.response.status === 422
                              ? " Are you a robot?"
                              : " Please try again.";
                          this.setState({
                            flash: {
                              show: true,
                              variant: "danger",
                              message: message,
                            },
                          });
                        } finally {
                          reCaptchaRef.reset();
                          actions.setSubmitting(false);
                        }
                      }}
                      render={({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setSubmitting,
                        setFieldValue,
                        submitForm,
                      }) => (
                        <Form id="contact-form">
                          <Row>
                            <Col>
                              <p className="lead">
                                Have a question? We'd love to hear from you.
                                Send us a message and we'll respond as soon as
                                possible
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  placeholder="Name"
                                  name="message.name"
                                  className="rounded-0"
                                  isInvalid={
                                    touched.message &&
                                    touched.message.name &&
                                    !!(errors.message && errors.message.name)
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.message &&
                                    errors.message.name &&
                                    "Name is a required field"}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  placeholder="Email"
                                  name="message.email"
                                  className="rounded-0"
                                  isInvalid={
                                    touched.message &&
                                    touched.message.email &&
                                    !!(errors.message && errors.message.email)
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.message &&
                                    errors.message.email &&
                                    "Email is a required field"}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  rows="5"
                                  name="message.body"
                                  className="rounded-0"
                                  isInvalid={
                                    touched.message &&
                                    touched.message.body &&
                                    !!(errors.message && errors.message.body)
                                  }
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message.body}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.message &&
                                    errors.message.body &&
                                    "Body is a required field"}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Button
                                  variant="primary"
                                  block
                                  className="rounded-0"
                                  disabled={isSubmitting}
                                  onClick={() => {
                                    setSubmitting(true);
                                    reCaptchaRef.execute();
                                  }}
                                >
                                  Submit
                                </Button>
                                <div className="w-100 d-flex justify-content-end">
                                  <Recaptcha
                                    ref={e => (reCaptchaRef = e)}
                                    sitekey={process.env.RECAPTCHA_SITEKEY}
                                    size="invisible"
                                    verifyCallback={async response => {
                                      setFieldValue(
                                        "reCaptchaResponseToVerify",
                                        response
                                      );
                                      await submitForm();
                                    }}
                                    badge="inline"
                                    className="mt-3"
                                  />
                                </div>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      )}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }
}
